import React from 'react'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

const Logo = () => {
  const { icon } = useStaticQuery(query)
  return <Img fluid={icon.childImageSharp.fluid} />
}

export const query = graphql`
  query FetchIcon {
    icon: file(relativePath: { eq: "bharu.png" }) {
      childImageSharp {
        fluid(maxWidth: 192) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Logo
